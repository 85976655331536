
<template>
    <div class="MyPubSub">
        <el-row>
            <el-col :span="24"   class="titbox">
                <el-col class="title" :span="8">我的订阅</el-col>
                <!-- <el-col class="text-right  addBtn" :span="16" @click.native="addNewProduct">
                    添加商品
                </el-col> -->
            </el-col>
        </el-row>
        <div class="sortBox">
            <div class="sortUlbox">
            <ul class="sortUl">
                <li
                v-for="(item, index) in NavProType"
                :key="index"
                @click="ProTypeFun(item, index)"
                :class="{ active: Boolean(NavProTypecheck === index) }"
                >
                {{ item.name }} <span v-if="item.num!==0">({{ item.num }})</span>
                </li>
            </ul>
            </div>
        </div>
        <div class="box" v-if="listArr.length > 0">
            <!-- <div  v-for="(item,index) of listArr" :key="index" class="listGoodsCom" >
                <div class="imgBox"   @click="JumpProductDetail(item)">
                    <img class="ProductPic"   :src="item.pic" alt="">
                    <div  class="soldOut"  v-if="item.quantity<Number(item.minOrderNum)">
                        <img  alt="补货中" src="@/assets/soldOut.png" />
                    </div>
                </div>
                <div class="infoBox">
                    <div class="price">
                        {{item.price| capitalize }} 
                        <img class="FavBox" @click="FavoriteFun(item)"  :src="item.FavoriteFlag?FavoriteCheckedImg:FavoriteCheckImg" alt="">
                    </div>
                    <div class="name">{{item.name}}</div>
                    <div class="partBox"><span>规</span>{{item.partSpecification}}</div>
                   
                    <div class="partBox"><span>效</span>{{item.validDateFromat.substr(0,10)}}</div>
                    <div class="desBox" >
                        <div v-if="item.promotionMap && item.promotionMap.length>0 ">
                            <span> {{item.promotionMap.length==1?item.promotionMap[0].type:"促销活动"}}</span>
                            {{item.promotionMap[0].name}}
                        </div>
                    </div>
                </div>
            </div> -->
            <shopItemPub
                v-for="(item, index) in listArr"
                :key="index"
                class="goods"
                :info="item"
                @InitFunSon="InitFun"
                ></shopItemPub>
        </div>
        <div class="box" v-else>
            <img class="NoProImg" src="@/assets/addProduct.png" alt="">
            <div class="btnBox"><span >暂无订阅</span></div>
        </div>

        <div class="pageBox">
          <el-pagination
            background
            layout="prev, pager, next"
            :hide-on-single-page="false"
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-size="size"
            :total="total"
          >
          </el-pagination>
        </div>
    </div>
</template>
<!-- 我的订阅  我的收藏夹 -->
<script>
const shopItemPub = () => import("components/index/shopItemPub.vue");
import { 
 
    ProductFavList
} from "api/member.js";
export default {
  name: "MyPubSub",
  inject:['reload'],
  data() {
    return {
         //加载loading条
        loading: false,
        listArr:[],
       

        NavProTypecheck:0,
         //导航栏信息
         NavProType: [
                {
                    type: 'ALL',
                    name: "全部",
                    num:0,
                },
                {
                    type: 'STOCKOUT',
                    name: "缺货",
                    num:0,
                },
                {
                    type: 'MONEYOFF',
                    name: "满减",
                    num:0,
                },
                {
                    type: 'SPECIALOFFER',
                    name: "特价",
                    num:0,
                },
                {
                    type: 'GIFT',
                    name: "赠品",
                    num:0,
                },
            ],
            parmesData: {}, //初始传参

      total: 0,
      current: 1, //当前页
      size:8, //每页数量
    };
  },
  methods: {
    // 导航切换
    ProTypeFun(item, index) {
         // 类型type 0->ALL(全部),1->STOCKOUT(缺货),2->MONEYOFF(满减促销),3->SPECIALOFFER(特价),4->GIFT(赠品),
      this.NavProTypecheck = index;
      this.parmesData.type = item.type;
      this.parmesData.current = 1; //当前页
      this.parmesData.size = this.size; //每页数量
      console.log(this.parmesData)
      this.InitFun(this.parmesData);
    },

    handleCurrentChange(val) {
      this.current = val;
      this.parmesData.current = this.current; //当前页
      this.parmesData.size = this.size; //每页数量
       this.InitFun(this.parmesData);
    },
    // 跳转商品详情页
    // JumpProductDetail(item){
    //     this.$router.push({ name: "ProductDetail", query: { id: item.id ,isRecentExpiration:item.isRecentExpiration } });
    // },

        InitFun(parmesData){
            if(parmesData==undefined){
                parmesData=this.parmesData
            }
            console.log(parmesData)
            // 获取收藏商品列表
            ProductFavList(parmesData).then(res=>{
                this.listArr=res.data.data.records
                this.$set(this.NavProType[this.NavProTypecheck],'num',res.data.data.total)
                this.total = res.data.data.total
                console.log(this.NavProType)
                this.listArr.forEach(item=>{
                    //进入收藏列表，默认为已收藏
                    this.$set(item,'FavoriteFlag',true)
                })
                console.log(this.listArr)
            })
        },
    
  },
  created() {
    //   初始进页面
    this.parmesData.type='ALL'
    this.parmesData.current = 1; //当前页
    this.parmesData.size =this.size; //每页数量
    this.InitFun(this.parmesData)
  },
  components: {
    shopItemPub,
  },
};
</script>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ed3129;
  color: #fff;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: #ed3129;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}

.MyPubSub{
  overflow: hidden;
}
  .sortBox {
    box-sizing: content-box;
    background-color: #ffffff;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    width: 100%;
    float: left;
    .sortUlbox:last-child {
      border-bottom: none;
    }
    .sortUlbox {
      width: 100%;
      padding: 8px 15px;
      float: left;
      line-height: 40px;
      border-bottom: 1px dashed #ccc;
      .ulTitle {
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        width: 136px;
        text-align: center;
        float: left;
      }
      .sortUl {
        width: calc(100% - 136px);
        float: left;
        li {
          float: left;
          margin-right: 40px;
          color: #666;
          font-size: 14px;
            cursor: pointer;
            &:hover{
                color: #ff3333;
            }
        }
        li.active {
          color: #ff3333;
          border-bottom: 2px solid #f33;
        }
      }
    }
  }

.titbox{
        padding: 20px;
        border-bottom:none;
    .title{
        border-left: 2px solid #FF3333;
        font-size: 16px;
        line-height: 22px;
        padding-left: 13px;
        color:#333333;
        font-weight: 600;
    }
    .addBtn{
            color: #409fe0;
            font-size: 13px;
            cursor: pointer;
    }
}

/deep/.el-col {
    padding: 0px;
}
  
.clear {
  clear: both;
}

.box {
    padding:15px 0;
    .NoProImg{
        display: block;
        width:30%;
        margin:0 auto;
        margin-top: 40px;
    }
}
.btnBox{
    width: 100%;
        span{
            margin: 0 auto;
            display:block;
            margin-top:20px;
            // background: #40bdc9;
            color:#000;
            font-weight: 500;
            width:100px;
            height:40px;
            text-align: center;
            line-height:40px;
            font-size:16px;  
        }  
    }

.listGoodsCom {
    width:calc( 25% - 28px);
    margin-right:8px;
    padding: 10px;
    float: left;
    background: #FFFFFF;
    margin-top:8px;
    border: 1px solid #ccc;
    &:nth-child(4n){
        margin-right: 0;
    }
    .imgBox {
      width:100%;
      height:auto;
      position: relative;
      >.ProductPic {
        height:204px;
        width:204px;
        margin: 0;
      }
      .soldOut{
          position:absolute;
          width: 100%;
          height: 100%;
          background: #0006;
          top: 0;
          img{
            position:absolute;
            width:46%;
            height: auto;
            display: block;
            right:6px;
            bottom:6px;
            opacity: 1;
          }
      }
      
    }

    .infoBox {
      margin-left:12px;
      text-align: left;
      .price{
        margin-top: 10px;
        font-size:20px;
        line-height:28px;
        color: #F43C38FF;
        font-weight:600;
        .FavBox{
           float: right;
           width:20px;
           height:20px;
           cursor:pointer;
           margin:2px 12px 0;
        }
      }
      .name {
        font-size: 16px;
        line-height:24px;
        color: #333333FF;
        font-weight: 600;
        margin-bottom:6px;
      }

      .partBox{
        height: 20px;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 12px;
        margin-bottom: 8px;
        color: #999;
        span{
            border: 1px solid #979797;
            color: #979797;
            padding: 1px;
            border-radius: 3px;
            margin-right: 3px;
        }
      }
      .desBox {
        font-size:13px;
        line-height:20px;
        height: 20px;
        color: #666666FF;
        // max-width:200px;
        margin-bottom: 6px;
        span{
            display: inline-block;
            border: 1px solid #CC6524FF;
            color: #CC6524FF;
            background: #FFEAD8FF;
            border-radius: 5px;
            font-size: 12px;
            line-height:16px;
            text-align: center;
            padding: 1px 3px;
            letter-spacing:1px;
            margin-right: 3px;
        }
      }

    }
}
</style>